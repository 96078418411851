import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from './store'
import {i18n} from './i18n/index.js'
// 分页组件
import Pagination from '@/components/Pagination'

require('@/utils/CustomUtil')

import ElementUI from "element-ui";
import vueEsign from 'vue-esign'
import "element-ui/lib/theme-chalk/index.css";

import "@/assets/theme.less";
import "@/assets/global.css";
import "@/assets/iconfont/iconfont.css"

import Ellipsis from '@/components/common/Ellipsis'
import WDialog from '@/components/common/WDialog'
import Tip from '@/components/common/Tip'

Vue.use(ElementUI);
Vue.use(Ellipsis);
Vue.use(WDialog);
Vue.use(Tip);
Vue.use(vueEsign)
Vue.use(ElementUI, {i18n: (key, value) => i18n.t(key, value)}) // 在注册Element时设置i18n的处理方法,可以实现当点击切换按钮后，elementUI可以自动调用.js语言文件实现多语言切换
Vue.component('Pagination', Pagination)
Vue.config.productionTip = false

//Vue.prototype.BASE_URL = (process.env.NODE_ENV === 'development' ? "http://192.168.1.37:7777/" : "http://47.103.74.98/flowable/");
//页面嵌入到管理平台显示，api需要改成https
//cn
// Vue.prototype.BASE_URL = (process.env.NODE_ENV === 'development' ? "https://flow.smartptt.cn/flowable/" : "https://flow.smartptt.cn/flowable/");
//en
Vue.prototype.BASE_URL = (process.env.NODE_ENV === 'development' ? "http://192.168.1.37:7777/" : "https://flow.realptt.com/flowable/");

Vue.prototype.$isNotEmpty = function (obj) {
    return (obj !== undefined && obj !== null && obj !== '' && obj !== 'null')
}

Vue.prototype.$getDefalut = function (obj, key, df) {
    return (obj === undefined || key === undefined || !this.$isNotEmpty(obj[key])) ? df : obj[key];
}

Vue.prototype.$deepCopy = function (obj) {
    return JSON.parse(JSON.stringify(obj))
}

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
