import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    nodeMap: new Map(),
    isEdit: null,
    selectedNode: {},
    selectFormItem: null,
    design:{},
    runningList: [],
    noTakeList: [],
    endList: [],
    diagramMode: 'design',
  },
  mutations: {
    SELECTED_NODE(state, val) {
      state.selectedNode = val
    },
    LOAD_FORM(state, val) {
      state.design = val
    },
    SET_IS_EDIT(state, val) {
      state.isEdit = val
    }
  },
  getters: {},
  actions: {},
  modules: {}
})
