const en = {
    process: {
        defaultFormName: 'Unnamed form',
        rootName: 'root',
        rootDesc: 'everyone',
        taskName: 'Task',
        remark: 'remark',
        next: 'next',
    },
    common: {
        download: 'download',
        yes: 'submit',
        ok: "ok",
        cancel: 'cancel',
        exit: 'exit',
        image: 'image',
        title: 'Workflow management',
        popTips: 'Tips',
        select: 'select',
        submit: 'submit',
        modify: 'modify',
        delete: 'delete',
    },
    tips: {
        addSuccess: "Add successfully",
        editSuccess: "Modified successfully",
        deleteSuccess: "deleted Successfully ",
        moveSuccess: "move Successfully"
    },
    login: {
        username: 'username',
        password: 'password',
        login: 'Log in',
        ruleUsername: 'Please enter your username',
        rulePassword: 'Please enter your password',
    },
    formPanel: {
        createProcess: 'create process',
        createGroup: 'create group',
        emptyTips: "No Data",
        exitPopMsg: 'Are you sure you want to quit?',
        edit: "edit",
        createTemplate: 'create',
        updateGroup: 'modify',
        deleteGroup: 'delete',
        popGroupMsg: 'Please enter a group name',
        popDeleteGroupMsg: 'Are you sure you want to delete group {name}?',
        popDeleteMsg: 'Are you sure you want to delete form {name}? It cannot be recovered after deletion',
        popStopMsg: 'Do you want the {edit}{name} form?',
        selectGroup: "Please select a group",
        errorMsg: 'The group name cannot be empty and contains less than 30 characters',
        button: {
            edit: 'edit',
            use: 'use',
            stop: 'stop',
            delete: 'delete',
            move: 'move',
        }
    },
    FormProcessDesign: {
        title: {
            baseSetting: " baseSetting",
            formSetting: " formSetting",
            processDesign: " processDesign",
            proSetting: " proSetting",
        },
        validResultErr: ' error',
        exitMsg: "Unpublished content will not be saved, do you want to exit directly?",
        publish: 'publish',
        publishMsg: "If you only want to preview, please select preview and confirm that the process takes effect immediately after the release. Do you want to continue?",
        popTips: 'Set item check',
        defaultValidErr: {
            a: 'Check the basic Settings',
            b: 'Please check the approval form Settings',
            c: 'Check the approval process and check the error information of the marked node',
            d: 'Please check the extension Settings',
            default: 'Unknown error',
        },
        validResult: {
            title: 'check...',
            action: 'handle',
            desc: 'Checking the Settings',
            checkSuccess: 'Check complete',
            checkFail: 'Check failure',
            checkDesc: 'Verify the Settings successfully. Do you want to submit it?',
            submit: 'submit',
            modify: 'modify'
        }
    },
    baseSetting: {
        iconTitle: 'icon',
        background: 'background',
        icon: 'icon',
        formName: 'name',
        remark: 'remark',
        group: 'group',
        errorTips: {
            name: 'The form name is not set',
            group: 'The form group is not set'
        },
        ruleFormName01: 'Please enter a form name',
        ruleFormName02: 'Form names can be between 1 and 20 in length',
        ruleFormRemark: 'Please enter a form remark',
        ruleFormGroup: 'Select the group you are in'
    },
    formDesign: {
        title: 'Component library',
        preview: 'preview',
        mobile: 'mobile',
        PC: 'PC',
        centerTips: "Select the control on the left and drag it here",
        rightTips: 'Select the control and edit it here',
        delMsg: 'Deleting a component will delete the conditions and related Settings that contain the component. Do you want to continue?',
        errorTips: {
            form: 'The form is empty. Please add components',
            nameRepeat: 'The form {title} has duplicate names',
            noSetOptions: '{title} No option is set',
            noAdd: "No component is added in the column"
        },
    },
    proSetting: {
        signMessage: 'Whether to sign upon approval or not',
        inactive: 'NO',
        active: 'YES',
        signTip: 'If signature is set here, then all executors must sign "upon consent"'
    },
    formConfig: {
        formName: 'formName',
        required: 'required',
        emptyTips: 'The current component does not support configuration',
        placeholderLabel: 'placeholder',
        placeholderValue: 'Please set the placeholder',
        decimal: 'decimal',
        showChinese: 'showChinese',
        color: 'color',
        multipleLabel: 'multiple',
        select: {
            label: 'Option',
            addLabel: "Add",
            addValue: "New option",
            placeholder: 'Please set the option'
        },
        date: {
            dateTimeTips: 'Please set the date prompt',
            dateTimeFormatLabel: 'format',
            yyyy: 'yyyy',
            yyyyMM: 'yyyy-MM',
            yyyyMMdd: 'yyyy-MM-dd',
            yyyyMMddHHmm: 'yyyy-MM-dd HH:mm',
            showLengthLabel: 'duration',
            startTimePlaceholder: 'start time',
            endTimePlaceholder: 'end time',
        },
        upload: {
            numLimitLabel: 'num',
            numLimitTips: 'Limit the maximum number of uploaded images (0 is not limited)',
            numLimitPlaceholder: 'Upload a few pictures at most',
            sizeLimitLabel: 'size',
            sizeLimitTips: 'Limit the maximum size of a single file -MB(0, no limit)',
            sizeLimitPlaceholder: 'Maximum size of a single file',
            typeLimitLabel: 'type',
            typeLimitTips: 'The suffix type of the file to be uploaded is restricted',
            typeLimitPlaceholder: 'You can set multiple suffix formats for uploading files',
            onlyReadLabel: "only read",
            imageSizeLimitTips: 'Limit the maximum size of a single image -MB (0, no limit)',
            imageSizeLimitPlaceholder: 'Maximum size of a single image',
            imageNumLimitTips: 'Limit the maximum number of uploaded images (0 is not limited)',
            imageNumLimitPlaceholder: 'Upload a few pictures at most'
        }
    },
    formItem: {
        inputPlaceholder: 'Please enter content',
        numberPlaceholder: 'Please enter the value',
        amountPlaceholder: 'Please enter the amount',
        amountChinese: 'Chinese',
        descPlaceholder: 'It\'s just a caption',
        selectPlaceholder: 'Please select options',
        datePlaceholder: 'Please select a  time',
        prickerPlaceholder: 'selector',
        dateRange: {
            timeLengthDefault: '0 (Duration is 0, please confirm)',
            timeLength: 'length',
            tips: "Select time first",
            year: 'year ',
            month: 'month ',
            day: 'day ',
            hour: 'hour ',
            minutes: 'minutes '
        },
        upload: {
            selectFile: 'Select',
            selectFilePlaceholder: 'Select',
            sizeTip1: ' | Only files in the [{data}] format can be uploaded, and a single attachment does not exceed {maxSize}MB',
            sizeTip2: ' | A single attachment does not exceed {maxSize}MB',
            beyondUploadTip: 'The maximum size of an image for a single image is {maxSize}MB',
            selectImagePlaceholder: 'Select',
            imageSizeTip: ' Each image should not exceed {maxSize}MB',
            notSupportedFormat: 'There are unsupported image formats'
        },
        name: {
            text: 'Text',
            textInput: 'Text',
            textareaInput: 'Textarea',
            numberInput: 'Number',
            amountInput: 'Amount',
            description: 'Description',
            select: 'Select',
            selectInput: 'Select',
            multipleSelect: 'Multiple',
            options: ['option1', 'option2'],
            date: 'Date',
            dateTime: 'Date',
            dateTimeRange: 'DateRange',
            upload: 'Upload',
            imageUpload: 'Image',
            fileUpload: 'File',
            other: 'Other',
            userPicker: 'UserPicker',
        }
    },
    node: {
        base: {
            title: 'title',
            placeholder: 'placeholder',
            errorInfo: 'error'
        },
        tree: {
            maxLimit: "A maximum of 8 items can be added",
            errorTips: 'A complete process must have a task',
            errorTips2: 'An error occurred. The parent node could not be found',
            add: 'add',
        },
        rootNode: {
            title: 'root',
            placeholder: 'everyone',
            defaultContent: 'everyone',
        },
        approvalNode: {
            name: 'Task',
            placeholder: 'Please set an executor',
            assignUserDefault: 'Please appoint an executor',
            selfDefault: 'self',
            selfSelect: {
                one: 'single',
                more: 'multiple'
            },
            formUser: {
                noSelect: 'Contact in the form (not selected)',
                select: 'The person in the form ({title})',
                remove: 'This form has been removed'
            },
            default: 'Unknown setting item',
            validate: {
                validateName: "The task name is not set",
                validateToUser: 'If the executor is not available, transfer to the designated person: [Please specify a specific person]',
            }
        },
        ccNode: {
            name: 'CC',
            placeholder: 'Please set the CC',
            contentDefault: 'Specified by root',
            validate: {
                errorInfo: 'Please select the person to be copied',
                showError: 'Cc node {name} No CC is set'
            }
        },
        conditionNode: {
            name: 'Conditional',
            condition: 'Conditions',
            levelLabel: 'priority',
            copy: 'copy',
            placeholder: 'Please set conditions',
            elsePlaceholder: 'Other conditions Enter the flow',
            belongDefault: '{title} belongs to []',
            belongSelect: '{title} belongs to {value}',
            belongSelect2: '{title} is one of [{value}]',
            openRight: "Other conditions do not need to be edited",
            moveLeft: "Other conditions must have the lowest priority",
            moveRight: 'Other conditions must have the lowest priority',
            validate: {
                nullError: 'Cannot be empty after a conditional branch',
                placeholder: 'Please set the branch condition',
                noSet: '{name} No condition is set',
                noSetConditions1: 'Please set a condition in condition group {name}',
                noSetConditions2: 'Condition {name} Condition group {child} No condition is set',
                perfectConditions1: 'Please refine the ${title} condition in the condition group {name}',
                perfectConditions2: 'Condition {name} Condition The {title} condition in the {groupName} group is not complete',
            }
        },
        end: "End of process",
    },
    nodeConfig: {
        formPermLabel: 'Form Authority',
        nodeRootLabel: 'Root',
        nodeTaskLabel: 'Task',
        nodeCCLabel: 'CC',
        formAuthority: {
            title: 'field',
            readOnly: 'read',
            editable: 'edit',
            hide: 'hide',
        },
        root: {
            desc: 'Select the person who can initiate the task. If you do not select it, it is open to everyone by default',
            select: "selected",
            tips: 'Please select the person who can initiate this task'
        },
        CC: {
            select: 'selected',
        },
        Task: {
            name: "name",
            desc: "description",
            descPlaceholder: 'Please enter a task description',
            userType: 'Select execution object',
            assignUser: "select",
            taskDelayLabel: 'Execution time',
            taskDelay: 'If this parameter is selected, the initiator can select the execution time of the task when initiating the task',
            delayTimeLabel: "That day ",
            taskDelayPlaceholder: 'Select',
            broadcast: 'The content of the broadcast is filled in by the initiator and broadcast when the executor receives the task',
            listing: 'The task list should be filled in by the initiator, and the executor should confirm it one by one after receiving it',
            userStatistics: 'Personnel identification, personnel identification statistics are carried out when shooting videos',
            selfSelect: {single: 'single', multiple: 'multiple'},
            formUser: 'Select the form contact entry',
            formUserPlaceholder: 'Select the form item that contains the contact',
            self: 'The promoter himself acts as the executor for approval',
            mode: {
                label: 'Execution mode',
                next: 'Countersign (in order of selection, everyone must execute)',
                and: 'Countersign (can be executed simultaneously, everyone must execute)'
            },
            AdvancedSettings: 'Other Settings',
            faceVerification: 'Face recognition is required to verify your identity when performing a task',
            assigneeLabel: 'Whether the task can be transferred',
            signLabel: 'Whether signature is required for execution of consent',
            signTips: 'If signature is required globally, this parameter does not take effect',
            commentLabel: 'Whether the executor needs to fill in the handling opinions when he completes',
            uploadTypeLabel: 'The following items must be uploaded when performing the task',
            uploadType: {location: 'location', image: 'image', video: 'video', recording: 'recording', file: 'file'},
            pickerTitle: {user: 'user', role: 'role'},
            approvalTypes: {assignUser: 'assignUser', selfSelect: 'selfSelect', self: 'self', formUser: 'formUser'},
            selectOrg: {user: 'user', role: 'role'},
            yes: 'yes',
            no: 'no'
        },
        condition: {
            levelLabel: 'Adjust',
            modifyLevelLabel: 'Drag condition adjustment',
            priority: 'priority ',
            level: 'Level {nowNodeLeave}',
            groupsLabel: 'groups relation',
            and: 'and',
            or: 'or',
            addGroup: 'Add condition group',
            addGroupDesc: 'Only required fields are required for approval',
            maxLimit: 'You can add a maximum of 10 condition groups'
        },
        conditionGroupItem: {
            name: 'conditions ',
            groupTypeLabel: 'conditional relation',
            groupContentDefault: 'Click + in the upper right corner to add a condition to this condition group',
            addGroupTitle: 'Selection of approval conditions',
            decider: 'decider',
            equal: 'equal',
            contained: 'contained',
            selectPlaceholder: 'select value',
            comparePlaceholder: "Input comparison value",
            comparePlaceholder2: "Enter possible values",
            userDesc: 'Belong to one of the people',
            selected: 'selector',
            explains: {
                "=": 'Equal',
                ">": 'Greater than',
                '>=': 'Greater than or equal',
                '<': 'Less than',
                '<=': 'Less than or equal',
                'IN': 'in',
                'B': 'x < value < y',
                'AB': 'x ≤ value < y',
                'BA': 'x < value ≤ y',
                'ABA': 'x ≤ value ≤ y',
            }
        }
    },
    orgPicker: {
        searchPlaceholder: 'search',
        selectAll: 'select all',
        back: 'back',
        leftEmptyTips: 'There seems to be no data',
        next: 'next',
        clear: 'clear',
        rightEmptyTips: 'Click on the list on the left to select data',
        leftTips: '{data} is selected'
    },
    menu: {
        flowable: 'Workflows',
        flowableDesc: 'Approve workflow creation, editing, and other setup actions',
        userFace: 'Face recognition',
        userFaceDesc: 'Upload the data required for face recognition'
    }, userFace: {
        dialogTitle: 'upload',
        submit: 'upload',
        reset: 'reset',
        search: 'search',
        dataValue: {existence: 'existence', absence: 'absence'},
        table: {
            idLabel: 'id',
            usernameLabel: 'account',
            nickNameLabel: 'name',
            imageLabel: 'image',
            dataLabel: 'data',
            quantityLabel: 'quantity',
            operateLabel: 'operate',
            indexLabel: "index",
            dataUserNameLabel: 'person name',
        },
        query: {
            usernameLabel: 'username',
            nickNameLabel: 'nickName',
            usernamePlaceholder: 'Please enter your username',
            nickNamePlaceholder: 'Please enter your nickName'
        }
    }
}

export default en
