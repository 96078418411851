import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const viewport = {
    content: "width=device-width, initial-scale=1.0, user-scalable=no",
};

const router = new Router({
    mode: 'hash',
    base: "/workflow_web",
    routes: [
        {
            path: "/",
            redirect: "/index",
        },
        {
            path: "/index",
            name: "index",
            component: () => import("@/views/Index.vue"),
            meta: {title: "登录", viewport: viewport},
        },
        {
            path: "/menu",
            name: "menu",
            component: () => import("@/views/Menu.vue"),
            meta: {title: "菜单", viewport: viewport},
        },
        {
            path: "/userFace",
            name: "userFace",
            component: () => import("@/views/admin/UserFace.vue"),
            meta: {title: "人脸识别管理", viewport: viewport},
        },
        {
            path: "/formsPanel",
            name: "formsPanel",
            component: () => import("@/views/admin/FormsPanel.vue"),
            meta: {title: "表单列表", viewport: viewport},
        },
        {
            path: "/admin/design",
            name: "design",
            component: () => import("@/views/admin/FormProcessDesign.vue"),
            meta: {title: "表单流程设计", viewport: viewport},
        },
        {
            path: "/attachment-preview",
            name: "preview",
            component: () => import("@/views/preview/index.vue"),
            meta: {title: "预览", viewport: viewport},
        },
        // {
        //   path: "/workspace",
        //   name: "workspace",
        //   component: () => import("@/views/workspace/index.vue"),
        //   meta: { title: "工作区", viewport: viewport },
        //   redirect: '/workspace/approval',
        //   children: [
        //     {
        //       path: "approval",
        //       name: 'approval',
        //       component: () => import('@/views/workspace/approval/index.vue'),
        //       meta: { title: "审批列表", viewport: viewport },
        //     },
        //     {
        //       path: "todo",
        //       name: 'todo',
        //       component: () => import('@/views/workspace/todo/index.vue'),
        //       meta: { title: "待我处理", viewport: viewport },
        //     },
        //     {
        //       path: "apply",
        //       name: 'apply',
        //       component: () => import('@/views/workspace/apply/index.vue'),
        //       meta: { title: "我发起的", viewport: viewport },
        //     },
        //     {
        //       path: "about",
        //       name: 'about',
        //       component: () => import('@/views/workspace/about/index.vue'),
        //       meta: { title: "关于我的", viewport: viewport },
        //     }
        //   ]
        // },
        // {
        //   path: "/workspace/process/instance/tabs",
        //   name: "processInstanceTabs",
        //   component: () =>
        //     import("@/views/workspace/process/ProcessInstanceTabs.vue"),
        //   meta: { title: "流程详情", viewport: viewport },
        // },
    ],
});
const whiteList = ["/", "/index", "/attachment-preview"]
router.beforeEach((to, from, next) => {
    let token = sessionStorage.getItem("token")
    if (token) {
        if (to.meta.title) {
            document.title = to.meta.title;
        }
        if (to.meta.content) {
            const head = document.getElementByTagName("head");
            const meta = document.createElement("meta");
            meta.name = "viewport";
            meta.content = "width=device-width, initial-scale=1.0, user-scalable=no";
            head[0].appendChild(meta);
        }
        // if (to.path === '/index') {
        //     next(from.path);
        // } else {
        //     next();
        // }
        next();
        sessionStorage.setItem("router-path", to.path);
    } else {
        // 没有token
        console.log("whiteList:" + whiteList);
        console.log("to.path:" + to.path);
        console.log("to.fullPath:" + to.fullPath);
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next()
        } else {
            next(`/index?redirect=${to.fullPath}`) // 否则全部重定向到登录页
        }
    }
});

export default router;
